<template>
  <div class="dataview">
    <!-- 头部 -->
    <div class="data-head">
      <div class="head-time">
        <div>
          <span>{{ curDate }}</span>
          <span style="margin: 0 10px;">{{ curTime }}</span>
          <span>{{ curDay }}</span>
        </div>
        <div class="home-btn" @click="$router.replace('/')">
          <img src="../assets/img/data_home.png" />
          <span>首页</span>
        </div>
      </div>
      <div class="head-btn">
        <div class="c-center btn" @click="fullScreen">
          <img class="icon" src="../assets/img/fullscreen.png" alt="" />
          <span>全屏</span>
        </div>
        <div class="c-center btn" @click="exit" style="margin-left: 20px;">
          <img class="icon" src="../assets/img/exit.png" alt="" />
          <span>退出</span>
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="databox-top">
        <div class="leftbox">
          <div class="count bgbox">
            <div class="chart">
              <div class="progress">
                <el-progress
                  color="#8B60EF"
                  :width="80"
                  :stroke-width="10"
                  type="circle"
                  :percentage="toiletOnlinePercent"
                ></el-progress>
                <span>公厕上线数</span>
              </div>
              <div class="progress">
                <el-progress
                  color="#8B60EF"
                  :width="80"
                  :stroke-width="10"
                  type="circle"
                  :percentage="transferPointPercent"
                ></el-progress>
                <span>转运站上线数</span>
              </div>
            </div>
            <div class="online">
              <div class="onlineitem">
                <div class="title">公厕总数:</div>
                <div v-for="(item, index) in toiletCount" :key="index" class="num">
                  {{ item }}
                </div>
              </div>
              <div class="onlineitem">
                <div class="title">转运站总数:</div>
                <div v-for="(item, index) in transferPointCount" :key="index" class="num">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="list bgbox">
            <div class="head">管养项目部排名</div>
            <div class="data-item  ">
              <el-table :data="listData" size="small" height="160">
                <el-table-column align="center" prop="projectDeptName" label="项目部" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="score" label="公厕得分"></el-table-column>
                <el-table-column align="center" prop="scoreType" label="评分">
                  <template slot-scope="scope">
                    <span v-if="scope.row.scoreType == '优秀'" style="color:#2CF7A3">{{ scope.row.scoreType }}</span>
                    <span v-if="scope.row.scoreType == '良好'" style="color:#2859D9">{{ scope.row.scoreType }}</span>
                    <span v-if="scope.row.scoreType == '不合格'" style="color:#F12C0C">{{ scope.row.scoreType }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="centerbox mpbox">
          <Map :showMapStyle="showMapStyle" :dataVivewmouver="dataVivewmouver" ref="boxMap"></Map>
        </div>
        <div class="rightbox">
          <div class="item-box alarm-top">
            <div class="data-item alarm-list">
              <div class="info-head" style="margin-bottom: 12px">
                <span style="color:#09B2FF;display:flex;justify-content: center">公厕实时报警信息</span>
                
              </div>
              <div class="auto alarmbox">
                <p v-if="washroomUnAlarmList.length == 0" class="zanwu">暂无数据</p>
                <div v-else>
                  <!-- 公厕详情列表，大于5条时滚动 -->
                  <template v-if="washroomUnAlarmList.length > 5">
                    <vueSeamlessScroll :data="washroomUnAlarmList" :class-option="scrollOption" class="hidden">
                      <div class="info-item" v-for="item in washroomUnAlarmList" :key="item.id">
                        <div>
                          <span>{{ item.siteName }}</span>
                          <span v-if="item.type === 1">（男厕氨气报警）</span>
                          <span v-if="item.type === 2">（女厕氨气报警）</span>
                          <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                          <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                          <span v-if="item.type === 5">（转运站报警）</span>
                        </div> 
                        <span class="infoBtn" @click="goAlarmcord(item)">查看详情</span >
                        
                          
                       
                        
                      </div>
                    </vueSeamlessScroll>
                  </template>
                  <template v-else>
                    <div class="info-item" v-for="item in washroomUnAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.siteName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                        <span v-if="item.type === 5">（转运站报警）</span>
                      </div>
                      <span class="infoBtn" @click="goAlarmcord(item)" >查看详情</span>
                      
                     
                      
                    
                    </div>
                  </template> 
                  
                </div>
              </div>
            </div>
          </div>
         
          <div class="list bgbox">
            <div class="head">转运站实时报警信息</div>
            <div class="box">
              <!-- 转运站报警列表，大于5条时滚动 -->
              <!-- 转运站已处理列表，报警列表少于5条时显示 -->
              
                <!-- 已处理列表大于剩下空间时滚动 -->
                <template v-if="stationUnAlarmList.length > 5 ">
                  <vueSeamlessScroll :data="stationUnAlarmList" :class-option="scrollOption" class="auto hidden">
                    <div class="info-item" v-for="item in stationUnAlarmList" :key="item.id">
                      <div>
                        <span>{{ item.siteName }}</span>
                        <span v-if="item.type === 1">（男厕氨气报警）</span>
                        <span v-if="item.type === 2">（女厕氨气报警）</span>
                        <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                        <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                        <span v-if="item.type === 5">（满载）</span>
                      </div>
                      <span class="infoBtn" @click="goAlarmcord(item)" >查看详情</span>
                        
                        
                        
                      
                    </div>
                  </vueSeamlessScroll>
                </template>
                <template v-else>
                  <div class="info-item" v-for="item in stationUnAlarmList" :key="item.id">
                    <div>
                      <span>{{ item.siteName }}</span>
                      <span v-if="item.type === 1">（男厕氨气报警）</span>
                      <span v-if="item.type === 2">（女厕氨气报警）</span>
                      <span v-if="item.type === 3">（男厕硫化氢报警）</span>
                      <span v-if="item.type === 4">（女厕硫化氢报警）</span>
                      <span v-if="item.type === 5">（满载）</span>
                    </div>
                    <span
                      class="infoBtn"
                     @click="goAlarmcord(item)"
                      >查看详情</span
                    >
                  </div>
                </template>
              
            </div>
          </div>
        </div>
      </div>
      <div class="databox-bottom">
        <div class="chartbox">
          <div class="chart-head">
            <span>公厕{{ chartType1 }}度氨气报警次数</span>
            <el-radio-group v-model="chartType1" size="mini" @change="chartChange($event, 1)">
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="chart-item">
            <Echart v-if="chartDataShow1" :option="chartData1" :navtor="true" :date="dateyear" :type="1"></Echart>
            <div class="echartShow" v-else></div>
          </div>
        </div>
        <div class="chartbox">
          <div class="chart-head">
            <span>公厕{{ chartType2 }}度硫化氢报警次数</span>
            <el-radio-group v-model="chartType2" size="mini" @change="chartChange($event, 2)">
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="chart-item">
            <Echart v-if="chartDataShow2" :option="chartData2" :navtor="true" :date="dateyear" :type="2"></Echart>
            <div class="echartShow" v-else></div>
          </div>
        </div>
        <div class="chartbox">
          <div class="chart-head">
            <span>转运站{{ chartType3 }}度报警次数</span>
            <el-radio-group v-model="chartType3" size="mini" @change="chartChange($event, 3)">
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="chart-item">
            <Echart v-if="chartDataShow3" :option="chartData3" :navtor="true" :date="dateyear" :type="3"></Echart>
            <div class="echartShow" v-else></div>
          </div>
        </div>
        <div class="chartbox">
          <div class="chart-head">
            <span>转运站{{ chartType4 }}度垃圾清运次数</span>
            <el-radio-group v-model="chartType4" size="mini" @change="chartChange2">
              <el-radio-button label="月"></el-radio-button>
              <el-radio-button label="年"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="chart-item">
            <Echart v-if="chartDataShow4" :option="chartData4" :navtor="true" :date="dateyear"></Echart>
            <div class="echartShow" v-else></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="foot">东营区综合行政执法局</div>
  </div>
</template>

<script>
import axios from "../request";
import Echart from "./Echart";
import * as echarts from "echarts";
import Map from "./map/Map";
import vueSeamlessScroll from "vue-seamless-scroll";

export default {
  name: "DataView",
  components: { Echart, vueSeamlessScroll, Map },
  data() {
    return {
      scrollOption: {
        limitMoveNum: 1,
      },
      showMapStyle: true,
      dataVivewmouver: true,
      month: 1,
      year: 2021,
      dateyear: true,
      timer: null,
      chartDataShow: "",
      curDate: "",
      curTime: "",
      curDay: "",
      chartType1: "月",
      chartType2: "月",
      chartType3: "月",
      chartType4: "月",
      listData: [],
      washroomCount: {
        toiletCount: 0,
        toiletOnlineCount: 0,
        toiletOfflineCount: 0,
        transferPointCount: 0,
        transferPointOnlineCount: 0,
        transferPointOfflineCount: 0,
      },
      toiletOnlinePercent: 0,
      transferPointPercent: 0,
      washroomAlarmList: [], //公厕报警
      washroomUnAlarmList: [], //公厕详情
      stationAlarmList: [],
      stationUnAlarmList: [],
      chartData1: null, //公厕氨气图表数据
      chartData2: null, //公厕硫化氢图表数据
      chartData3: null, //转运站报警图表数据
      chartData4: null, //转运站清运图表数据
      chartDataShow1: false, //公厕氨气图表数据
      chartDataShow2: false, //公厕硫化氢图表数据
      chartDataShow3: false, //转运站报警图表数据
      chartDataShow4: false, //转运站清运图表数据
    };
  },
  computed: {
    toiletCount() {
      return String(this.washroomCount.toiletCount).padStart(4, "0");
    },
    transferPointCount() {
      return String(this.washroomCount.transferPointCount).padStart(4, "0");
    },
  },
  watch: {
    washroomCount(val) {
      this.toiletOnlinePercent = val.toiletOnlineCount == 0 ? 0 : Math.floor((val.toiletOnlineCount / val.toiletCount) * 100);
      this.transferPointPercent =
        val.transferPointOnlineCount == 0 ? 0 : Math.floor((val.transferPointOnlineCount / val.transferPointCount) * 100);
    },
  },
  created() {
    let time = new Date();
    this.month = time.getMonth();
    this.year = time.getFullYear();
    this.getProjectList();
    this.getWashRoomAlarm();
    this.getStationAlarm();
    this.getChartData();
    this.getItemCount();
    this.getChartData(1, this.year, this.month);
    this.getChartData(2, this.year, this.month);
    this.getChartData(3, this.year, this.month);
    this.getStationChartData(this.year, this.month);
    this.$nextTick(function() {
      console.log(this.$refs.boxMap)
      this.$refs.boxMap.$el.children[0].style = `background:rgba(145, 199, 249, 0.29);top:20px; box-shadow:
             0px 2px 5px 0px rgba(1, 3, 53, 0.35); border: none;color:#EEFFFF;right:10px`;
      this.$refs.boxMap.$el.children[2].style = `background:rgba(145, 199, 249, 0.29);left:20%; box-shadow:
             0px 2px 5px 0px rgba(1, 3, 53, 0.35); border: none;;color:#EEFFFF`;
      this.$refs.boxMap.$el.children[1].style = `color:#FFFFFF;top: 12px;right: 15%;`;       

    });
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getDate();
    }, 1000);
    this.name = sessionStorage.getItem("name") ?? localStorage.getItem("name");
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 跳转到报警记录
     goAlarmcord(item){
      console.log(11111)
      this.$router.push({ name: 'alarmrecord', params: { userId: item.id, status: item.siteType, typeID: item.status } })
      

    },
    chartData(type) {
      const style = type === 1 || type === 3;
      return {
        // tooltip: {
        //     show: true
        // },
        // grid: {
        //     left: 15,
        //     right: 15,
        //     bottom: 40,
        //     top: 40,
        // },
        // xAxis: {
        //     type: "category",
        //     data: ["西三路","西三路","西三路","西三路","西三路","西三路","西三路","西三路","西三路",],
        //     axisTick: {
        //         show: false,
        //     },
        //     axisLabel: {
        //         rotate: 45,
        //         interval: 0,
        //         align: "center",
        //         verticalAlign: "btoom",
        //         margin: 20,
        //         color: "#ffffff"
        //     },
        // },
        // yAxis: {
        //     show: false,
        // },
        // series: [
        //     {
        //         data: [21,54,32,87,23,57,32,54,23],
        //         type: "bar",
        //         showBackground: true,
        //         barWidth: 12,
        //         backgroundStyle: {
        //             color: "#FFFFFF",
        //             borderRadius: [20, 20, 0, 0],
        //         },
        //         itemStyle: {
        //             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        //                 { offset: 0, color: style ? "#F12200" : "#00F1E6" },
        //                 { offset: 1, color: style ? "#FFEEEB" : "#EDFFFE" },
        //             ]),
        //             borderRadius: [20, 20, 0, 0],
        //         },
        //         label: {
        //             show: false,
        //             position: "top",
        //             color: "#FF610B",
        //             fontSize: 15,
        //             fontWeight: "bold",
        //         },
        //     },
        // ],
        // title: {
        //     text: '特性示例：渐变色 阴影 点击缩放',
        //     subtext: 'Feature Sample: Gradient Color, Shadow, Click Zoom'
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: 15,
          right: 15,
          bottom: 45,
          top: 40,
        },
        xAxis: {
          type: "category",
          data: ["西三路", "西三路", "西三路", "西三路", "西三路", "西三路", "西三路", "西三路", "西三路"],
          axisTick: {
            show: false,
          },
          axisLabel: {
            rotate: 45,
            interval: 0,
            align: "center",
            verticalAlign: "btoom",
            margin: 20,
            color: "#ffffff",
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
              width: 1,
              type: "solid",
            },
          },
          z: 10,
        },
        yAxis: {
          show: false,
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 10,
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 15,
          },
        ],
        series: [
          {
            data: [21, 54, 32, 87, 23, 57, 32, 54, 23],
            type: "bar",
            showBackground: true,
            barWidth: 12,
            backgroundStyle: {
              color: "#FFFFFF",
              borderRadius: [20, 20, 0, 0],
            },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: style ? "#F12200" : "#00F1E6" },
                { offset: 1, color: style ? "#FFEEEB" : "#EDFFFE" },
              ]),
              borderRadius: [20, 20, 0, 0],
            },
            label: {
              show: false,
              position: "top",
              color: "#FF610B",
              fontSize: 15,
              fontWeight: "bold",
            },
          },
        ],
      };
    },
    chartChange(e, type) {
      if (e === "月") {
        this.getChartData(type, this.year, this.month);
        this.dateyear = true
      } else {
        this.getChartData(type, this.year);
        this.dateyear = false
      }
    },
    chartChange2(e) {
      console.log(e)
      if (e === "月") {
        this.getStationChartData(this.year, this.month);
        
      } else {
        this.getStationChartData(this.year);
        
      }
    },
    getDate() {
      const time = new Date();
      (this.curDate = time
        .toLocaleDateString("chinese", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replaceAll("/", "-")),
        (this.curTime = time.toLocaleTimeString("chinese", {
          hourCycle: "h23",
        })),
        (this.curDay = time.toLocaleDateString("chinese", {
          hourCycle: "h23",
          weekday: "long",
        }));
    },
    exit() {
      this.$confirm("是否退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("token");
          sessionStorage.removeItem("token");
          this.$router.replace("/login");
        })
        .catch(() => false);
    },
    fullScreen() {
      document.fullscreenElement ? document.exitFullscreen() : document.documentElement.requestFullscreen();
    },
    // 获取公厕数量
    async getItemCount() {
      let data = await axios.get("/data/index/findSiteInfo");
      if (data) {
        this.washroomCount = data;
      }
    },
    // 获取项目部排名
    async getProjectList() {
      let data = await axios.get("/data/index/toiletAssess");
      if (data) {
        this.listData = data;
      }
    },
    // 获取公厕实时报警信息
    async getWashRoomAlarm() {
      let data = await axios.get("/data/index/alarmRecord/findList", {
        params: { siteType: 1 },
      });
      if (data) {
        this.washroomAlarmList = data.noDisposeLists;
        this.washroomUnAlarmList = data.disposeLists;
      }
      console.log(this.washroomAlarmList);
      console.log(this.washroomUnAlarmList);
    },
    // 获取转运站实时报警信息
    async getStationAlarm() {
      let data = await axios.get("/data/index/alarmRecord/findList", {
        params: { siteType: 2 },
      });
      if (data) {
        this.stationAlarmList = data.noDisposeLists;
        this.stationUnAlarmList = data.disposeLists;
        console.log(this.stationUnAlarmList)
      }
    },
    // 获取图表数据
    async getChartData(type, year, month = null) {
      const query = { alarmType: type, year, month };
      let data = await axios.get("/data/index/findAlarmStatistics", {
        params: query,
      });
      console.log(data);
      if (data) {
        const chartData = this.chartData(type);
        const num = [],
          name = [];
        data.forEach((item) => {
          num.push(item.alarmCount);
          name.push(item.siteName);
        });
        chartData.xAxis.data = name;
        chartData.series[0].data = num;
        this["chartData" + type] = chartData;
        this["chartDataShow" + type] = num.length > 0 ? true : false;
      }
    },
    // 获取转运站图表数据
    async getStationChartData(year, month = null) {
      const query = { year, month };
      let data = await axios.get("/data/index/transferPointAssess", {
        params: query,
      });
      if (data) {
        const chartData = this.chartData();
        const num = [],
          name = [];
        data.forEach((item) => {
          num.push(item.alarmCount);
          name.push(item.siteName);
        });
        chartData.xAxis.data = name;
        chartData.series[0].data = num;
        this.chartData4 = chartData;
        this.chartDataShow4 = num.length > 0 ? true : false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "number";
  src: url("../assets/font/number.TTF");
}
.dataview {
  // display: flex;
  // flex-direction: column;
  width: 100%;
  height: 100%;
  // min-width: 1366px;
  min-height: 768px;
  overflow: auto;
  background-image: url("../assets/img/data_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.data-head {
  flex: none;
  height: 74px;
  background-image: url("../assets/img/data_head2.png");
  background-size: 100% 74px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  color: white;
}
.head-time {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.head-btn {
  display: flex;
  align-items: flex-start;
  padding-top: 2px;
  > div {
    height: 30px;
    img {
      margin-right: 6px;
    }
  }
}
.btn {
  cursor: pointer;
}
.home-btn {
  display: flex;
  align-items: center;
  color: #31e9ff;
  padding: 0 20px;
  cursor: pointer;
}
.content {
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  height: calc(100% - 40px - 84px);
}
.databox-top {
  height: calc(60% - 20px);
  display: flex;
  margin-bottom: 20px;
}
.databox-bottom {
  height: 40%;
  background-image: url("../assets/img/bottom_box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  .chartbox {
    width: 25%;
    height: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    .chart-head {
      display: flex;
      justify-content: space-between;
      color: #09b2ff;
      font-size: 1.2rem;
    }
    .chart-item {
      flex: auto;
    }
  }
}
.leftbox {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: calc(335 / 1366 * 100%);

  .count {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    color: #8193ff;
    height: calc(50% - 10px);
    .chart {
      display: flex;
      justify-content: space-around;
      .progress {
        display: flex;
        flex-direction: column;
        align-items: center;
        > span {
          margin-top: 10px;
        }
      }
    }
    .online {
      display: flex;
      justify-content: space-around;
      .onlineitem {
        display: flex;
        align-items: center;
        .title {
          font-size: 1.1rem;
        }
        .num {
          font-family: "number";
          padding: 4px 6px 4px 4px;
          font-size: 1.1rem;
          background: #8b60ef;
          color: white;
          margin-left: 5px;
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  .list {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 20px;
    //padding-bottom: 200px;
    height: calc(50% - 10px);
    .head {
      color: #09b2ff;
      text-align: center;
      font-size: 1.2rem;
      padding: 10px 0;
    }
    .table-box {
      flex: auto;
      overflow: auto;
      text-align: center;
      tr {
        line-height: 2;
      }
      th {
        color: #93d1ff;
      }
      td {
        color: white;
      }
    }
  }
}
.rightbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-width: calc(335 / 1366 * 100%);
  .alarm-top {
    height: calc(50% - 10px);
    box-sizing: border-box;
  }
  .alarm-list {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .head {
    color: #09b2ff;
    text-align: center;
    font-size: 1.3rem;
    padding: 10px 0;
  }
  .list {
    height: calc(50% - 10px);
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .box {
      display: flex;
      flex-direction: column;
      flex: auto;
      overflow: hidden;
      .alarmBtn {
        color: #f12c0c;
      }
      
    }
  }
}
.infoBtn {
  color: #2859d9;
  cursor: pointer;
}
.hidden {
  overflow: hidden;
}
.centerbox {
  flex: auto;
  width: 100%;
  // display: flex;
  // flex-direction: column;
  margin: 0 20px;
  height: 100%;
  box-sizing: border-box;
}
.zanwu {
  color: #FFFFFF;
  text-align: center;
}
.bgbox {
  background-image: url("../assets/img/data_box.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // background-position: center;
  padding: 10px;
}
.mpbox {
  padding: 10px;
  background: url("../assets/img/data_box.png") center/100% 100% no-repeat;
}
.foot {
  flex: none;
  text-align: center;
  color: white;
  font-size: 1.2rem;
  height: 40px;
}
.bgbox ::v-deep .el-progress {
  background-color: white;
  border-radius: 50%;
}
.info-item {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  white-space: nowrap;
  font-size: 7px;
  margin: 0 40px;
  .check {
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #2859d9;
    line-height: 23px;
    font-size: 9px;
    cursor:pointer
  }
}

.bgbox ::v-deep .el-table__header {
  background: #010335 !important;
  border: none;
  //opacity:0.1  !important;
  .has-gutter th {
    border: none;
    background: #010335 !important;
    color: #93d1ff;
    font-size: 15px;
    //  opacity:0.1  !important;
  }
}
.bgbox ::v-deep .el-table__body {
  background: #010335 !important;
  border: none;
  .el-table__row td {
    border: none;
    background: #010335 !important;
    color: #f5fbff;
    font-size: 13px;
    //  opacity:0.1  !important;
  }
}
.bgbox ::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #010335 !important;
}
.echartShow {
  height: 100%;
  background: url("../assets/img/zanwu.png") center/40% no-repeat, #010335;
}
::v-deep .el-table__empty-block {
  background: #010335;

  .el-table__empty-text {
    font-size: 16px;
    color: #f5fbff;
  }
}
.bgbox ::v-deep.el-table__body-wrapper,
.el-table__footer-wrapper,
.el-table__header-wrapper {
  background-color: #010335 !important;
}
</style>